<template>
  <div class="card mb-20">
    <div class="table-responsive text-nowrap">
      <table class="table table-bordered">
        <thead class="table-lighter">
          <tr>
            <th>정산 전 총 수익금</th>
            <th>정산 후 총 수익금</th>
            <th>정산 전 금일 수익금</th>
            <th>정산 후 금일 수익금</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatNum(dailyCountInfo.before_total_profit, 4) }}$</td>
            <td>{{ formatNum(dailyCountInfo.total_accumulated_profit, 4) }}$</td>
            <td>{{ formatNum(dailyCountInfo.before_day_profit, 4) }}$</td>
            <td>{{ formatNum(dailyCountInfo.day_profit, 4) }}$</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>일일결산</h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select class="form-select display-inline wp-15 mr-5" data-allow-clear="true" v-model="info.exchange">
          <option v-for="data in exchangeList" :key="data.key" :value="data.key" >{{ data.label }}</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">일자</h5>
        <input type="date" class="form_control wp-20 mr-5 display-inline" v-model="info.start_date" />
        ~
        <input type="date" class="form_control wp-20 ml-5 display-inline" v-model="info.end_date" />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="fetchFn()">
          검색
        </button>
        <button @click="infoClear" type="button" class="btn btn-secondary mlr-5">
          클리어
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>수익금</th>
              <th>발생날짜</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="dailyProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in dailyProfitList" :key="i">
              <td>{{ formatNum(data[1], 4) }}</td>
              <td>{{ data[0] }}</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging v-if="totalPages > 1" :page="page" :changePage="changePage" :totalPages="totalPages" />
      </div>
    </div>
  </div>
</template>
<script setup>
import ThePaging from "@/components/public/ThePaging.vue";
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { formatNum } from "@/utils/common";
import { exchangeList } from "@/utils/exchangeList";

const store = useStore();
const props = defineProps(['exchange']);
const dailyProfitList = computed(() => store.state.closing.dailyProfitList);
const dailyCountInfo  = computed(() => store.state.closing.dailyCountInfo);
const page            = computed(() => store.state.closing.page);
const totalPages      = computed(() => store.state.closing.totalPages);
const isLoading       = computed(() => store.state.closing.isLoading);
// let exchange = ref(0);
let info = {
  exchange: 0,
  start_date: "",
  end_date: "",
};
async function fetchFn(reqInfo) {
  if (!reqInfo) store.commit("closing/setInfo", info);
  else store.commit("closing/setInfo", reqInfo);
  store.commit("closing/setPage", 1);
  await store.dispatch("closing/getDailyProfit");
}

const infoClear = async () => {
  info = {
    exchange: 0,
    start_date: "",
    end_date: "",
  };
  fetchFn();
};

const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getDailyProfit");
};

watch( () => props.exchange, (val) => {
  info.exchange = val;
  let reqInfo = {
    exchange: val,
    start_date: "",
    end_date: "",
  };
  fetchFn(reqInfo);
});

fetchFn();

</script>
