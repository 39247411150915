<template>
    <div class="error_page">
        <div>
            <div class="title">
                <img src="@/assets/image/logo.svg" alt="logo">
                <h5>RisingX Referral Admin</h5>
            </div>
            <div class="content">
                <p>서비스를 불러오는데 실패했습니다.</p>
                <p>새로고침 버튼을 눌러주세요.</p>
                <button class="button" @click="resetError()">새로고침 하기</button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useRouter } from 'vue-router';

const router = useRouter();
const resetError = () => {
    router.go(-1);
}
</script>