export const exchangeList = [
  {
    key: 0,
    stringKey: "",
    label: "ALL",
  },
  {
    key: 1,
    stringKey: "okx",
    label: "OKX",
  },
  {
    key: 2,
    stringKey: "bingx",
    label: "BingX",
  },
  {
    key: 3,
    stringKey: "toobit",
    label: "Toobit",
  },
  {
    key: 4,
    stringKey: "deepcoin",
    label: "DeepCoin",
  },
  // {
  //   key: 5,
  //   stringKey: "gateio",
  //   label: "Gate.io",
  // },
  {
    key: 6,
    stringKey: "binance",
    label: "Binance",
  },
];

export const getExchangeLabel = (flag) => {
    const exchange = exchangeList.find(item => String(item.key) === String(flag));
    return exchange ? exchange.label : null;
}

export const getExchangeStrKey = (flag) => {
  const exchange = exchangeList.find(item => String(item.key) === String(flag));
  return exchange ? exchange.stringKey : null;
}