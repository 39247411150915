import router from "@/routes";
import { API } from "./apiAuth";

// login for Access Token api
const postTokenApi = async (info) => {
  try {
    const response = await API.post("admin/token", info);
    return response.data;
  } catch (error) {
    // if (error.response.status === 401) {
    //   return error.response;
    // }
    return;
  }
};

// create admin user endpoint api
const createAdminApi = async (info) => {
  try {
    const response = await API.post("admin/create", info);
    return response;
  } catch (error) {
    if (error.response.status === 400) {
      return error.response;
    }
    return;
  }
};

// update admin user endpoint api
const updateAdminApi = async (admin_id ,info) => {
  try {
    const response = await API.put(`admin/update?admin_id=${admin_id}`,info);
    return response;
  } catch (error) {
    // if (error.response.status === 401) {
    //   return error.response;
    // }
    return;
  }
};

// update password api
const updateAdminPwdApi = async (admin_id ,info) => {
  try {
    const response = await API.put(`admin/update-password/${admin_id}`,info);
    return response;
  } catch (error) {
    return;
  }
};

// delete admin user endpoint api
const deleteAdminApi = async (admin_id) => {
  try {
    const response = await API.delete(`admin/delete?admin_id=${admin_id}`);
    return response;
  } catch (error) {
    return;
  }
};

// get admin user list api
const getAdminApi = async () => {
  try {
    const response = await API.get(`admin/list`);
    return response.data;
  } catch (error) {
    if(error.response.status === 401){
      router.push("/login");
    }
    return;
  }
};
export default {
  postTokenApi,
  createAdminApi,
  updateAdminApi,
  updateAdminPwdApi,
  deleteAdminApi,
  getAdminApi,
};
