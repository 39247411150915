<template>
  <div class="content_wrapper">
    <div class="page_title">
      <i class="tf-icons ti ti-receipt ti_box"></i>
      <h4 class="title">UID 로그</h4>
    </div>
    <div class="tabMenu_inner border-0 mt-0">
      <ul class="tab_link_box ctgy02">
        <li v-for="data in exchangeList" :key="data.key" :class="{ active: exchange === data.key }"
        @click="changeTab(data.key)">{{ data.label }}</li>
      </ul>
    </div>
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="card">
        <div class="card_header">
          <h5 class="card_title"><i class="ti ti-search mr-5"></i>로그 검색</h5>
        </div>
        <!---->
        <div class="form_inner">
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">USER_NO</h5>
            <input
              type="number"
              v-model="info.user_no"
              class="form_control display-inline wp-36"
              placeholder="Search"
              @keyup.enter="infoSearch"
            />
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">UID</h5>
            <input
              type="text"
              v-model="info.uid"
              class="form_control display-inline wp-36"
              placeholder="UID search"
              @keyup.enter="infoSearch"
            />
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">등록 상태</h5>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="Checkbox3"
                value="2"
                v-model="connectSuccess"
                @change="checkType"
              />
              <label for="Checkbox3">등록 성공</label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                id="Checkbox1"
                value="1"
                v-model="connectWating"
                @change="checkType"
              />
              <label for="Checkbox1">등록 실패</label>
            </div>
            <p class="fs-13">
              ( Toobit 일 경우, 등록 실패가 아닌 등록 대기 중인 상태입니다. )
            </p>
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">거래소</h5>
            <select
              class="form-select display-inline wp-15 mr-5"
              data-allow-clear="true"
              v-model="info.exchange"
            >
            <option v-for="data in exchangeList" :key="data.key" :value="data.key === 0 ? data.stringKey :data.key" >{{ data.label }}</option>
            </select>
          </div>
          <div class="col-12 mb-15 search_group">
            <h5 class="item_name">일자</h5>
            <input
              type="date"
              class="form_control wp-20 mr-5 display-inline"
              v-model="info.from_data"
            />
            ~
            <input
              type="date"
              class="form_control wp-20 ml-5 display-inline"
              v-model="info.to_data"
            />
          </div>
          <div class="filter-btn">
            <button
              type="button"
              class="btn btn-primary mlr-5"
              @click="infoSearch"
            >
              검색
            </button>
            <button
              @click="infoClear"
              type="button"
              class="btn btn-secondary mlr-5"
            >
              클리어
            </button>
          </div>
        </div>
        <!---->
      </div>
    </div>
    <!--  -->
    <div class="col-12 pl-10 pr-10 mb-20">
      <div class="card">
        <div class="table-responsive text-nowrap dataTables_wrapper">
          <table class="table">
            <thead class="table-light">
              <tr>
                <th>USER_NO</th>
                <th>거래소</th>
                <th>UID</th>
                <th>등록 상태</th>
                <th>연동 시간</th>
                <th>정산 비율</th>
                <th>IP</th>
              </tr>
            </thead>
            <tbody v-if="!isLoading">
              <tr v-if="logList.length == 0">
                <td colspan="7">데이터 내역이 없습니다.</td>
              </tr>
              <tr v-for="(data, i) in logList" :key="i">
                <td>{{ data.user_no }}</td>
                <td>
                  {{ getExchangeLabel(data.exchange) }}
                </td>
                <td>{{ data.uid }}</td>
                <td>{{ stateFormat(data.connet, data.exchange) }}</td>
                <td>{{ data.datetime.slice(0, 16) }}</td>
                <td>{{ data.ratio }}</td>
                <td>{{ data.ip }}</td>
              </tr>
            </tbody>
            <tbody v-if="isLoading">
              <td colspan="8" class="list_loading">
                <div class="loading_spinner">
                  <div class="loading_circle"></div>
                </div>
              </td>
            </tbody>
          </table>
          <ThePaging
            v-if="totalPages > 1"
            :page="page"
            :changePage="changePage"
            :totalPages="totalPages"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { exchangeList, getExchangeLabel } from "@/utils/exchangeList";
const store = useStore();
const logList = computed(() => {
  return store.state.manage.logList;
});
const totalPages = computed(() => {
  return store.state.manage.totalPages;
});
const page = computed(() => {
  return store.state.manage.page;
});
const isLoading = computed(() => {
  return store.state.manage.isLoading;
});
const exchange = ref(0);
let info = {
  status: "",
  from_data: "",
  to_data: "",
  user_no: "",
  exchange: "",
  uid : "",
};
const connectWating = ref(false);
const connectSuccess = ref(false);
const infoSearch = async () => {
  if (
    (connectWating.value && connectSuccess.value) ||
    (!connectWating.value && !connectSuccess.value)
  )
    info.status = "";
  else if (connectWating.value) info.status = "1";
  else info.status = "2";
  let reqInfo = {
    status: info.status,
    from_data: info.from_data,
    to_data: info.to_data,
    user_no: info.user_no,
    exchange: info.exchange,
    uid : info.uid.trim(),
  };
  fetchFn(reqInfo);
};
const stateFormat = (state, exchange) => {
  let str;
  if (exchange == "3" && state == 1) {
    str = "등록 대기 중";
  } else if (state == 1) {
    str = "등록 실패";
  } else if (state == 2) {
    str = "등록 성공";
  } else {
    str = "";
  }
  return str;
};
const infoClear = async () => {
  info = {
    status: "",
    from_data: "",
    to_data: "",
    user_no: "",
    exchange: "",
    uid: "",
  };
  fetchFn(info);
};

const changePage = async (str) => {
  store.commit("manage/setPage", str);
  await store.dispatch("manage/getUidLog");
};
const changeTab = async (num) => {
  exchange.value = num;
  info.exchange = exchange.value == 0 ? "" : exchange.value;
  let reqInfo = {
    status: "",
    from_data: "",
    to_data: "",
    user_no: "",
    exchange: exchange.value == 0 ? "" : exchange.value,
    uid: "",
  };
  store.commit("manage/setPage", 1);
  store.commit("manage/setReqLogInfo", reqInfo);
  await store.dispatch("manage/getUidLog");
};
const fetchFn = async (info) => {
  store.commit("manage/setPage", 1);
  store.commit("manage/setReqLogInfo", info);
  await store.dispatch("manage/getUidLog");
};
const createFn = async () => {
  fetchFn(info);
};
createFn();
</script>
