<template>
  <div class="tabMenu_inner border-0 mt-0">
    <ul class="tab_link_box ctgy02">
      <li v-for="data in exchangeList" :key="data.key" :class="{ active: exchange === data.key }"
        @click="changeTab(data.key)">{{ data.label }}</li>
    </ul>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>페이백 내역</h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">USER_NO</h5>
        <input
          type="number"
          v-model="info.user_no"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch()"
        />
      </div>
      <div class="col-12 mb-15 search_group">
            <h5 class="item_name">UID</h5>
            <input
              type="text"
              v-model="info.uid"
              class="form_control display-inline wp-36"
              placeholder="UID search"
              @keyup.enter="infoSearch"
            />
          </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
        <option v-for="data in exchangeList" :key="data.key" :value="data.key" >{{ data.label }}</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">일자</h5>
        <input
          type="date"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.start_date"
        />
        ~
        <input
          type="date"
          class="form_control wp-20 ml-5 display-inline"
          v-model="info.end_date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <div class="mb-20" v-if="isDate">
    <div class="table-responsive text-nowrap">
      <p class="mb-20">{{ `${searchDate.start_date} ~ ${searchDate.end_date}` }}</p>
      <table class="table table-bordered">
        <thead class="table-lighter">
          <tr>
            <th v-if="searchExchange == 0">총 지급액</th>
            <th v-if="searchExchange == 1 || searchExchange == 0">OKX</th>
            <th v-if="searchExchange == 2 || searchExchange == 0">BingX</th>
            <th v-if="searchExchange == 3 || searchExchange == 0">Toobit</th>
            <th v-if="searchExchange == 4 || searchExchange == 0">DeepCoin</th>
            <th v-if="searchExchange == 6 || searchExchange == 0">Binance</th>
          </tr>
        </thead>
        <tbody class="bg-w">
          <tr>
            <td v-if="searchExchange == 0">{{ formatNum(datePaybackInfo.total_profit, 4) }}$</td>
            <td v-if="searchExchange == 1 || searchExchange == 0"> {{ formatNum(datePaybackInfo.exchange_totals?.OKX, 4) }}$</td>
            <td v-if="searchExchange == 2 || searchExchange == 0"> {{ formatNum(datePaybackInfo.exchange_totals?.BingX, 4) }}$</td>
            <td v-if="searchExchange == 3 || searchExchange == 0"> {{ formatNum(datePaybackInfo?.exchange_totals?.Toobit, 4) }}$</td>
            <td v-if="searchExchange == 4 || searchExchange == 0"> {{ formatNum(datePaybackInfo.exchange_totals?.DeepCoin, 4) }}$</td>
            <td v-if="searchExchange == 6 || searchExchange == 0"> {{ formatNum(datePaybackInfo.exchange_totals?.Binance, 4) }}$</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mb-20" v-if="info.user_no && referralInfo?.user_no">
    <div class="table-responsive text-nowrap">
      <h3 class="pt-10 pb-10 fs-15 fl">EX ID {{ referralInfo.user_no }} 의 거래소별 전체 수익금</h3>
      <table class="table table-bordered">
        <thead class="table-lighter">
          <tr>
            <th v-for="data in exchangeList" :key="data.key">{{ data.label }}</th>
          </tr>
        </thead>
        <tbody class="bg-w">
          <tr>
            <td>{{formatNum(referralInfo.total_sum,4)}}$</td>
            <td>{{formatNum(referralInfo.total_okx,4)}}$</td>
            <td>{{formatNum(referralInfo.total_bingx,4)}}$</td>
            <td>{{formatNum(referralInfo.total_toobit,4)}}$</td>
            <td>{{formatNum(referralInfo.total_deepcoin,4)}}$</td>
            <td>{{formatNum(referralInfo.total_binance,4)}}$</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>USER_NO</th>
              <th>USER EMAIL</th>
              <th>거래소</th>
              <th>UID</th>
              <th>날짜</th>
              <th>지급액</th>
              <th>지급일자</th>
              <th>지급내역</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="paybackList.length == 0">
              <td colspan="9">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in paybackList" :key="i">
              <td>{{ data.user_no }}</td>
              <td>{{ data.user_email }}</td>
              <td>{{ data.exchange }}</td>
              <td>{{ data.uid }}</td>
              <td>{{ data.datetime }}</td>
              <td>{{ formatNum(data.payment,4) }}</td>
              <td>{{ data.paymentdate }}</td>
              <td>{{ data.paymentdetails }}</td>
              <td>{{ data.status }}</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import ThePaging from "@/components/public/ThePaging.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { formatNum } from "@/utils/common";
import { exchangeList } from "@/utils/exchangeList";
const store = useStore();
const paybackList      = computed(() => store.state.referral.paybackList );
const datePaybackInfo  = computed(() => store.state.referral.datePaybackInfo);
const referralInfo     = computed(() => store.state.referral.referralInfo );
const page             = computed(() => store.state.referral.page );
const totalPages       = computed(() => store.state.referral.totalPages );
const isLoading        = computed(() => store.state.referral.isLoading );
const isDate = ref(false);
const searchExchange = ref(0);
const searchDate = ref({
  start_date:"",
  end_date:"",
});
let exchange = ref(0);
let info = {
  exchange: 0,
  uid : "",
  user_no: "",
  start_date: "",
  end_date: "",
};
const infoSearch = async () => {
  let reqInfo = {
    exchange: info.exchange ?? 0,
    uid : info.uid.trim(),
    user_no: info.user_no,
    start_date: info.start_date,
    end_date: info.end_date,
  };
  if(info.user_no){
    await  store.dispatch("referral/getExchangeTotal",info.user_no);
  }
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
  isDate.value = info.start_date && info.end_date;
  searchDate.value = {
    start_date : info.start_date,
    end_date : info.end_date,
  }
  searchExchange.value = info.exchange;
};
const infoClear = async () => {
  info = {
    exchange: 0,
    uid : "",
    user_no: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPaybackInfo", info);
  store.commit("referral/setPage", 1);
  await store.dispatch("referral/getPaybackLog");
};
const changePage = async (str) => {
  store.commit("referral/setPage", str);
  await store.dispatch("referral/getPaybackLog");
};
const changeTab = async (state) => {
  info.exchange = state;
  exchange.value = state;
  let reqInfo = {
    exchange: exchange.value ?? 0,
    uid: "",
    user_no: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
};
const createFn = async () => {
  let reqInfo = {
    exchange: 0,
    uid:"",
    user_no: "",
    start_date: "",
    end_date: "",
  };
  store.commit("referral/setPage", 1);
  store.commit("referral/setPaybackInfo", reqInfo);
  await store.dispatch("referral/getPaybackLog");
};
createFn();
</script>
