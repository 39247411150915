<template>
  <div class="content_wrapper">
    <div class="page_title mb-0">
      <i class="ti tf-icons ti-tool"></i>
      <h4 class="title">레퍼럴 관리</h4>
      <div class="tabMenu_inner border-0">
        <div class="tab_link_box">
          <router-link to="/referral/payback" :class="{ active: subPath === 'payback' }"
            >페이백 내역</router-link
          >
          <router-link
            to="/referral/register"
            :class="{ active: subPath === 'register' }"
            >파일 처리 내역</router-link
          >
        </div>
      </div>
    </div>
    <router-view></router-view>
  </div>
</template>
<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";
const route = useRoute();
const subPath = computed(()=>{
  return route.path.split("/")[2];
})

   

</script>
