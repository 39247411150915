<template>
  <div class="dash-chart">
    <div v-for="(series, index) in chartSeries" :key="index" class="chart-container">
  <h3 style="text-align:center;font-weight:400;font-size:15px;color:rgb(67 67 67);">{{ series.name }}</h3>
  <apexchart 
    type="bar" 
    :options="chartOptions" 
    :series="[series]" 
    height="350">
  </apexchart>
</div>
  </div>
</template>

<script setup>
import {ref} from 'vue';
const props = defineProps({
  exchangeArr: { type: Array },
});

const chartOptions = {
  chart: {
    type: "bar",
    height: 350,
    toolbar:{
      show:false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "55%", // 막대의 너비
      endingShape: "rounded", // 끝 모양
    },
  },
  dataLabels: {
    enabled: false, // 막대에 값 표시 비활성화
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"], 
  },
  xaxis: {
    // categories: ["OKX", "BingX", "toobit", "Deepcoin", "Gate.io", "Binance"], 
    categories: ["OKX", "BingX", "toobit", "Deepcoin","Binance"], 
  },
  fill: {
    type:"gradient",
    gradient:{
      shade:"",
      type: "vertical", 
      shadeIntensity: 0.3, 
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100],
    }
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return `${val.toLocaleString()} $`; 
      },
    },
  },
};
const chartSeries = ref([
  {
    name: "금일",
    data: [
      { x: "OKX", y: props.exchangeArr[0].daily_profit, fillColor: "rgb(0, 143, 251)" }, 
      { x: "BingX", y: props.exchangeArr[1].daily_profit, fillColor: "rgb(0, 227, 150);" }, 
      { x: "Toobit", y: props.exchangeArr[2].daily_profit, fillColor: "rgb(254, 176, 25)" }, 
      { x: "Deepcoin", y: props.exchangeArr[3].daily_profit, fillColor: "#ff662d" },
      // { x: "Gate.io", y: 5000, fillColor: "#ce4fff" },
      { x: "Binance", y: props.exchangeArr[4].daily_profit, fillColor: "#674fff" },
    ],
  },
  {
    name: "금주",
    data: [
      { x: "OKX", y: props.exchangeArr[0].weekly_profit, fillColor: "rgb(0, 143, 251)" }, 
      { x: "BingX", y: props.exchangeArr[1].weekly_profit, fillColor: "rgb(0, 227, 150);" }, 
      { x: "Toobit", y: props.exchangeArr[2].weekly_profit, fillColor: "rgb(254, 176, 25)" }, 
      { x: "Deepcoin", y: props.exchangeArr[3].weekly_profit, fillColor: "#ff662d" },
      // { x: "Gate.io", y: 5000, fillColor: "#ce4fff" },
      { x: "Binance", y: props.exchangeArr[4].weekly_profit, fillColor: "#674fff" },
    ],
  },
  {
    name: "금월",
    data: [
      { x: "OKX", y: props.exchangeArr[0].monthly_profit, fillColor: "rgb(0, 143, 251)" }, 
      { x: "BingX", y: props.exchangeArr[1].monthly_profit, fillColor: "rgb(0, 227, 150);" }, 
      { x: "Toobit", y: props.exchangeArr[2].monthly_profit, fillColor: "rgb(254, 176, 25)" }, 
      { x: "Deepcoin", y: props.exchangeArr[3].monthly_profit, fillColor: "#ff662d" },
      // { x: "Gate.io", y: 5000, fillColor: "#ce4fff" },
      { x: "Binance", y: props.exchangeArr[4].monthly_profit, fillColor: "#674fff" },
    ],
  },
]);

</script>

<style lang="scss" scoped></style>
