<template>
  <div class="col-12 pl-10 pr-10 mb-20">
    <div class="tabMenu_inner border-0 mt-0">
      <ul class="tab_link_box ctgy02 diff">
        <li v-for="data in filterExchangeList" :key="data.key" :class="{ active: select.exchange === data.key }"
        @click="changeTab(data.key)">{{ data.label }}</li>
      </ul>
    </div>
    <div class="card">
      <div class="card_header">
        <h5 class="card_title" v-if="select.exchange == 3"><i class="ti ti-file mr-5"></i> 파일 처리 현황 및 파일 등록</h5>
        <h5 class="card_title" v-else><i class="ti ti-file mr-5"></i> 파일 처리 현황</h5>
      </div>
      <div class="card_body">
        <!-- <h3>Trade Count</h3> -->
        <!-- <form actopm="/submit" method="post" enctype="multipart/form-data"> -->
        <div class="calendar_wrap">
          <div class="pf_calendar">
            <div class="cal_set">
              <h3 class="title">File Calendar</h3>
              <div class="cal_inner">
                <form action="" id="" name="">
                  <select id="year" name="year" v-model="select.year">
                    <option v-for="year in yearArr" :key="year" :value="year">
                      {{ year }}
                    </option>
                  </select>
                  <select id="month" name="month" v-model="select.month">
                    <option v-for="i in 12" :key="i" :value="i">{{ i }}</option>
                  </select>
                </form>
              </div>
            </div>
            <TheCalender :select="select" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalMsg :msg="msg" v-if="modalState"/>
</template>
<script setup>
import { computed, reactive } from "vue";
import { useStore } from "vuex";
import ModalMsg from "@/components/modal/ModalMsg.vue";
import TheCalender from "@/components/public/TheCalender.vue";
import { exchangeList } from "@/utils/exchangeList";
const store = useStore();
const filterExchangeList = computed(()=>{
  return exchangeList.slice(1);
});
const modalState = computed(() => store.state.admin.modalState );
const msg        = computed(() => store.state.referral.msg );
const nowDate = new Date();
let select = reactive({
  year: nowDate.getFullYear(),
  month: nowDate.getMonth() + 1,
  exchange : 1,
});
let yearArr = [nowDate.getFullYear(), nowDate.getFullYear() - 1];

const changeTab = (state) => {
  select.exchange = state;
} 
</script>
<style scoped>
.card_body{
  background-color: aliceblue;
}
.pf_calendar{
  background-color: transparent !important;
  border: none !important;
}
.file_area select{
  border: 1px solid #c2c2c2;
}
</style>
