<template>
  <div class="content_wrapper">
    <div class="pl-10 pr-10 mb-20">
      <div class="dash_title">
        <h1>DashBoard</h1>
        <span>[REPORT] {{ reportDate }}</span>
      </div>
      <div class="dash_board_wrap" v-if="isLoading">
        <div class="loading_spinner_ov">
          <div class="loading_circle"></div>
        </div>
      </div>
      <div class="dash_board_wrap">
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 ">
          <div class="dash_box ct01" @click="modalOpen(1)">
            <i class="ti ti-user"></i>
            <div class="dash_text_area">
              <h3>Total Member</h3>
              <p class="ml-10 txt_green">{{ dashboardInfo.total_count || 0 }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 ">
          <div class="dash_box ct01" @click="modalOpen(2)">
            <i class="ti ti-user-plus"></i>
            <div class="dash_text_area">
              <h3>New Member</h3>
              <p class="ml-10 txt_green">{{ dashboardInfo.total_today || 0 }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 ">
          <div class="dash_box box_ctgy ct01" @click="modalOpen(3)">
            <div class="main_dash">
              <i class="ti ti-coins"></i>
              <div class="dash_text_area">
                <h3>Total Profit</h3>
                <p class="ml-10 txt_green">
                  {{ formatNum(dashboardInfo.total_profit, 4) }}$
                </p>
              </div>
            </div>
            <div class="before_area">
              <p>
                Before {{ formatNum(dashboardInfo.total_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box box_ctgy" @click="modalOpen(3)">
            <div class="main_dash">
              <i class="ti ti-currency-dollar ti-xl"></i>
              <div class="dash_text_area">
                <h3>This Daily Profit</h3>
                <p class="ml-10">{{ formatNum(dashboardInfo.day_profit, 4) }}$</p>
              </div>
            </div>
            <div class="before_area">
              <p>
                Before {{ formatNum(dashboardInfo.day_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box box_ctgy" @click="modalOpen(3)">
            <div class="main_dash">
              <i class="ti ti-currency-dollar ti-xl"></i>
              <div class="dash_text_area">
                <h3>This Week Profit</h3>
                <p class="ml-10">
                  {{ formatNum(dashboardInfo.week_profit, 4) }}$
                </p>
              </div>
            </div>
            <div class="before_area">
              <p>
                Before {{ formatNum(dashboardInfo.week_befor_profit, 4) }}$
              </p>
            </div>
          </div>

        </div>
        <div class="col-md-4 col-12 mb-20 pr-10 pl-10 diff">
          <div class="dash_box box_ctgy" @click="modalOpen(3)">
            <div class="main_dash">
              <i class="ti ti-currency-dollar ti-xl"></i>
              <div class="dash_text_area">
                <h3>This Month Profit</h3>
                <p class="ml-10">
                  {{ formatNum(dashboardInfo.month_profit, 4) }}$
                </p>
              </div>
            </div>

            <div class="before_area">
              <p>
                Before {{ formatNum(dashboardInfo.month_befor_profit, 4) }}$
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isLoading">
        <p class="col-12 mt-20 pr-10 pl-10">
          ※ Toobit, Binance 은 2일 전 데이터 / DeepCoin, OKX, BingX 는 1일 전 데이터입니다.
        </p>
        <div class="dash-chart">
          <TheBar v-if="!isChartLoading" :exchangeArr="exchangeArr"></TheBar>
        </div>
      </div>
    </div>
  </div>
  <ModalList :status="status" v-if="modalState && (status == 1 || status == 2)" />
  <ModalProfit :exchangeArr="exchangeArr" :dashboardInfo="dashboardInfo" v-if="modalState && status == 3" />
</template>
<script setup>
import { autoLeftPad, formatNum, numFloor } from "@/utils/common";
import ModalList from "./modal/ModalList.vue";
import ModalProfit from "./modal/ModalProfit.vue";
import TheBar from "./public/TheBar.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { exchangeList } from "@/utils/exchangeList";
const store = useStore();
const dashboardInfo = computed(() => store.state.manage.dashboardInfo);
const monthCountInfo = computed(() => store.state.closing.monthCountInfo);
const weekCountInfo = computed(() => store.state.closing.weekCountInfo);
const dailyCountInfo = computed(() => store.state.closing.dailyCountInfo);
const modalState = computed(() => store.state.admin.modalState);
const isDashboardInfo = computed(() => {
  const dashboard = store.state.manage.dashboardInfo;
  return dashboard && typeof dashboard === 'object' && Object.keys(dashboard).length > 0;
})
const exchangeArr = ref([...exchangeList.slice(1)]);
let info = {
  exchange: 0,
  start_date: "",
  end_date: "",
};
const isLoading = ref(false);
const isChartLoading = ref(false);
const status = ref(null);
const date = new Date();
const reportDate = `${date.getFullYear()}
.${autoLeftPad(date.getMonth() + 1, 2)}
.${autoLeftPad(date.getDate(), 2)} 
${date.getHours()}:00`;

const modalOpen = (state) => {
  status.value = state;
  store.commit("admin/changeModalState", true);
};
const createdFn = async () => {
  if (!isDashboardInfo.value) {
    isLoading.value = true;
  }
  isChartLoading.value = true;
  await store.dispatch("manage/getDashBoard");
  isLoading.value = false;
  for (const obj of exchangeArr.value) {
    await fetchExchangeData(obj);
  }
  isChartLoading.value = false;
};
const fetchExchangeData = async (exchange) => {
  try {
    info.exchange = exchange.key;
    store.commit("closing/setInfo", info);
    await store.dispatch("closing/getProfitMonth", exchange.key);
    await store.dispatch("closing/getWeekExchange", exchange.key);
    await store.dispatch("closing/getDailyProfit");
    exchange.monthly_profit = numFloor(monthCountInfo.value.monthly_profit, 4) || 0;
    exchange.total_profit = numFloor(monthCountInfo.value.total_accumulated_profit, 4) || 0;
    exchange.weekly_profit = numFloor(weekCountInfo.value.weekly_profit, 4) || 0;
    exchange.daily_profit = numFloor(dailyCountInfo.value.day_profit, 4) || 0;
    exchange.before_monthly_profit =
      numFloor(monthCountInfo.value.before_monthly_profit, 4) || 0;
    exchange.before_total_profit =
      numFloor(monthCountInfo.value.before_total_profit, 4) || 0;
    exchange.before_weekly_profit =
      numFloor(weekCountInfo.value.before_weekly_profit, 4) || 0;
    exchange.before_daily_profit = numFloor(dailyCountInfo.value.before_day_profit, 4) || 0;
  } catch (error) {
    return;
  }

};
createdFn();
</script>
