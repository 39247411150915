import api from "@/api/apiReferral";
import { autoLeftPad } from "@/utils/common";

export default {
  namespaced: true,
  state() {
    return {
      msg: "",
      reqCalenderInfo : {
        fromdate: "",
        exchange : 1,
      },

      paybackInfo: {},
      page: 1,
      totalPages: 0,
      isLoading: false,
      //res
      referralInfo : {},
      datePaybackInfo : {
        total_profit : "",
        exchange_totals : [],
      },
      calenderInfo: [],
      paybackList: [],

    };
  },
  mutations: {
    setMsg(state) {
      if (state.status === 500) {
        state.msg = "엑셀 파일에 필요한 컬럼이 없습니다.";
      } else if (state.status === 200) {
        state.msg = "등록하였습니다.";
      } else {
        state.msg = "실패하였습니다.";
      }
    },
    reqCalenderInfo(state, payload) {
      state.reqCalenderInfo = {
        fromdate : `${payload.year}-${autoLeftPad(payload.month, 2)}`,
        exchange : payload.exchange,
      }

    },
    setPaybackInfo(state, payload) {
      state.paybackInfo = {
        per_page: 10,
        ...payload
      };
    },
    setPage(state, str) {
      if (str == "prev") {
        state.page = state.page - 5 <= 0 ? 1 : state.page - 5;
      } else if (str == "prevEnd") {
        state.page = 1;
      } else if (str == "next") {
        state.page = state.page + 5 > state.totalPages ? state.totalPages : state.page + 5;
      } else if (str == "nextEnd") {
        state.page = state.totalPages;
      } else {
        state.page = str;
      }
    },
  },
  actions: {
    async getCalender(context) {
      try {
        let response = await api.getCalenderApi(context.state.reqCalenderInfo);
        context.state.calenderInfo = response.results;
      } catch (error) {
        return;
      }
    },
    async getExchangeTotal(context,user_no) {
      try {
        let response = await api.getExchangeTotalApi(user_no);
        context.state.referralInfo = response;
      } catch (error) {
        return;
      }
    },
    async getPaybackLog(context) {
      try {
        context.state.totalPages = 1;
        context.state.isLoading = true;
        let response = await api.getPaybackLogApi(
          context.state.paybackInfo,
          context.state.page
        );
        context.state.isLoading = false;
        if (response && response.message !== 'canceled') {
          context.state.paybackList = response.result;
          context.state.totalPages = response.pagination.total_pages;
          context.state.datePaybackInfo = {
            exchange_totals : response.exchange_totals,
            total_profit : response.total_profit,
          }
        } else {
          context.state.paybackList = [];
        }
      } catch (error) {
        return;
      }
    },
  },
};
