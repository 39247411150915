<template>
    <div class="card mb-20">
    <div class="table-responsive text-nowrap">
      <table class="table table-bordered">
        <thead class="table-lighter">
          <tr>
            <th>정산 전 총 수익금</th>
            <th>정산 후 총 수익금</th>
            <th>정산 전 금주 수익금</th>
            <th>정산 후 금주 수익금</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatNum(weekCountInfo.before_total_profit, 4) }}$</td>
            <td>{{ formatNum(weekCountInfo.total_accumulated_profit, 4) }}$</td>
            <td>{{ formatNum(weekCountInfo.before_weekly_profit, 4) }}$</td>
            <td>{{ formatNum(weekCountInfo.weekly_profit, 4) }}$</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>주간결산</h5>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">USER_NO</h5>
        <input
          type="number"
          v-model="info.user_no"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">UID</h5>
        <input
          type="text"
          v-model="info.uid"
          class="form_control display-inline wp-36"
          placeholder="UID search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
        <option v-for="data in exchangeList" :key="data.stringKey" :value="data.stringKey" >{{ data.label }}</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">주간 검색</h5>
        <input
          type="week"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear()"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>USER_NO</th>
              <th>거래소</th>
              <th>UID</th>
              <th>수익금</th>
              <th>발생시간</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="monthProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in monthProfitList" :key="i">
              <td>{{ data.user_no }}</td>
              <td>{{ data.exchange }}</td>
              <td>{{ data.uid }}</td>
              <td>{{ formatNum(data.profit, 4) }}</td>
              <td>
                {{ data.datetime?.replace("T", " ").slice(0, 16) }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
  <ModalMsg
    v-if="modalState"
    :msg="fetchStatus == 200 ? '성공적으로 보냈습니다.' : '실패하였습니다. 다시 시도해주세요.'"
  />
</template>
<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { formatNum } from "@/utils/common";
import ModalMsg from "@/components/modal/ModalMsg.vue";
import { exchangeList, getExchangeStrKey } from "@/utils/exchangeList";
const store = useStore();
const props = defineProps(['exchange']);

const monthProfitList = computed(() => store.state.closing.monthProfitList );
const weekCountInfo   = computed(() => store.state.closing.weekCountInfo );
const page            = computed(() => store.state.closing.page );
const totalPages      = computed(() => store.state.closing.totalPages );
const isLoading       = computed(() => store.state.closing.isLoading );
const fetchStatus     = computed(() => store.state.closing.fetchStatus );
const modalState      = computed(() => store.state.admin.modalState );
const date = new Date();
const todayWeek = getWeekFromISODate(date);
let info = {
  type: 0,
  date: todayWeek,
  user_no: "",
  exchange: "",
  uid: "",
};
const initInfo = {
  ...info,
  date: "",
};
function getWeekFromISODate(dt) {
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
  const week1 = new Date(dt.getFullYear(), 0, 4);
  const weekNumber =
    1 +
    Math.round(
      ((dt.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    );
  return `${dt.getFullYear()}-W${weekNumber}`;
}
async function fetchFn(exchange,reqInfo) {
  if (!reqInfo) store.commit("closing/setInfo", initInfo);
  else store.commit("closing/setInfo", reqInfo);
  store.commit("closing/setPage", 1);
  (exchange == 0 || exchange) && await store.dispatch("closing/getWeekExchange", exchange);
  await store.dispatch("closing/getUserProfit");
}

const infoSearch = () => {
  let reqInfo = {
    ...info,
    date: info.date.replace("-W", "") ?? "",
  };
  fetchFn(null,reqInfo);
};
const infoClear = () => {
  info = {
    ...initInfo
  }
  fetchFn();
};

const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getUserProfit");
};

watch(() => props.exchange, (val) => {
  info.exchange = getExchangeStrKey(val);
  let exchange = getExchangeStrKey(val);
  let reqInfo = {
    ...initInfo,
    exchange: exchange,
  };
  fetchFn(val,reqInfo);
});

fetchFn(0);

</script>
