<template>
  <div class="card mb-20">
    <div class="card_header">
      <h5 class="card_title"><i class="ti ti-files mr-5"></i>누적 결산</h5>
      <p class="bg-success mr-20">누적 결산 금액 : {{ formatNum(totalProfit,4) }}</p>
    </div>
    <div class="form_inner">
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">USER_NO</h5>
        <input
          type="number"
          v-model="info.user_no"
          class="form_control display-inline wp-36"
          placeholder="Search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">UID</h5>
        <input
          type="text"
          v-model="info.uid"
          class="form_control display-inline wp-36"
          placeholder="UID search"
          @keyup.enter="infoSearch"
        />
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">거래소</h5>
        <select
          class="form-select display-inline wp-15 mr-5"
          data-allow-clear="true"
          v-model="info.exchange"
        >
        <option v-for="data in exchangeList" :key="data.stringKey" :value="data.stringKey" >{{ data.label }}</option>
        </select>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">결산 상태</h5>
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="Checkbox1"
            value="1"
            v-model="before"
            @change="checkType"
          />
          <label for="Checkbox1">결산 전</label>
        </div>
        <div class="form-check form-check-inline">
          <input
            type="checkbox"
            id="Checkbox3"
            value="2"
            v-model="after"
            @change="checkType"
          />
          <label for="Checkbox3">결산 완료</label>
        </div>
      </div>
      <div class="col-12 mb-15 search_group">
        <h5 class="item_name">주간 검색</h5>
        <input
          type="week"
          class="form_control wp-20 mr-5 display-inline"
          v-model="info.date"
        />
      </div>
      <div class="filter-btn">
        <button type="button" class="btn btn-primary mlr-5" @click="infoSearch">
          검색
        </button>
        <button
          @click="infoClear()"
          type="button"
          class="btn btn-secondary mlr-5"
        >
          클리어
        </button>
      </div>
    </div>
  </div>
  <p class="mb-20">※수익이 발생하지 않은 사용자는 제외된 리스트입니다.</p>
  <!--  -->
  <div class="col-12 mb-20">
    <div class="card">
      <div class="table-responsive text-nowrap dataTables_wrapper">
        <table class="table">
          <thead class="table-light">
            <tr>
              <th>week</th>
              <th>USER_NO</th>
              <th>거래소</th>
              <th>UID</th>
              <th>수익금</th>
              <th>결산상태</th>
            </tr>
          </thead>
          <tbody v-if="!isLoading">
            <tr v-if="totalProfitList.length == 0">
              <td colspan="7">데이터 내역이 없습니다.</td>
            </tr>
            <tr v-for="(data, i) in totalProfitList" :key="i">
              <td>{{ `${String(data.week).slice(0,4)}년 ${String(data.week).slice(4,6)}주` }}</td>
              <td>{{ data.user_no }}</td>
              <td>{{ data.exchange }}</td>
              <td>{{ data.uid }}</td>
              <td>{{ formatNum(data.profit, 4) }}</td>
              <td>{{ data.status == 0 ? '결산 전' : '결산 완료'}}</td>
            </tr>
          </tbody>
          <tbody v-if="isLoading">
            <td colspan="8" class="list_loading">
              <div class="loading_spinner">
                <div class="loading_circle"></div>
              </div>
            </td>
          </tbody>
        </table>
        <ThePaging
          v-if="totalPages > 1"
          :page="page"
          :changePage="changePage"
          :totalPages="totalPages"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import ThePaging from "@/components/public/ThePaging.vue";
import { formatNum } from "@/utils/common";
import { exchangeList, getExchangeStrKey } from "@/utils/exchangeList";
const store = useStore();
const props = defineProps(['exchange']);
const totalProfitList = computed(() => store.state.closing.totalProfitList );
const totalProfit     = computed(() => store.state.closing.totalProfit );
const page            = computed(() => store.state.closing.page );
const totalPages      = computed(() => store.state.closing.totalPages );
const isLoading       = computed(() =>  store.state.closing.isLoading );
const before = ref(false);
const after = ref(false);
const date = new Date();
const todayWeek = getWeekFromISODate(date);
let info = {
  type: 0,
  date: todayWeek,
  user_no: "",
  exchange: "",
  uid: "",
  status:"",
};
const initInfo = {
  ...info,
  date: "",
};

function getWeekFromISODate(dt) {
  dt.setHours(0, 0, 0, 0);
  dt.setDate(dt.getDate() + 3 - ((dt.getDay() + 6) % 7));
  const week1 = new Date(dt.getFullYear(), 0, 4);
  const weekNumber =
    1 +
    Math.round(
      ((dt.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7
    );
  return `${dt.getFullYear()}-W${weekNumber}`;
}

async function fetchFn (reqInfo){
  if (!reqInfo) store.commit("closing/setInfo", initInfo);
  else store.commit("closing/setInfo", reqInfo);
  store.commit("closing/setPage", 1);
  await store.dispatch("closing/getTotalProfit");
}

const infoSearch = () => {
  if (
    (before.value && after.value) ||
    (!before.value && !after.value)
  )
    info.status = "";
  else if (before.value) info.status = 0;
  else info.status = 1;
  let reqInfo = {
    ...info,
    date: info.date?? "",
  };
  fetchFn(reqInfo);
};
const infoClear = () => {
  info = {
    ...initInfo
  }
  fetchFn();
};
const changePage = async (str) => {
  store.commit("closing/setPage", str);
  await store.dispatch("closing/getTotalProfit");
};

watch(() => props.exchange, (val) => {
  info.exchange = getExchangeStrKey(val);
  let exchange = getExchangeStrKey(val);
  let reqInfo = {
    ...initInfo,
    exchange: exchange,
  };
  fetchFn(reqInfo);
});

fetchFn();
</script>
