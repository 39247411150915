import { API } from "./apiAuth";

// 모든 요청 취소 함수
const cancelAllRequests = () => {
  abortControllers.forEach(controller => controller.abort());
  abortControllers = []; // 배열 초기화
};

// excel 등록 조회 캘린더 api
const getCalenderApi = async (info) => {
  try {
    const response = await API.get(
      `manage/manage-check-date?fromdate=${info.fromdate}&exchange=${info.exchange}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};

let abortControllers = [];
// 유저 페이백 내역 조회 api
const getPaybackLogApi = async (info, page) => {
  const abortController = new AbortController();
  const signal = abortController.signal;
  try {
    cancelAllRequests();
    abortControllers.push(abortController);
    const response = await API.get(
      `admin-payment-report?page=${page}&per_page=${info.per_page}&exchange=${info.exchange}&start_date=${info.start_date}&end_date=${info.end_date}&user_no=${info.user_no}&uid=${info.uid}`,
      { signal }
    );
    abortControllers = abortControllers.filter(ctrl => ctrl !== abortController);
    return response.data;
  } catch (error) {
    abortControllers = abortControllers.filter(ctrl => ctrl !== abortController);
    if(abortControllers.length > 0){
      return { message : error.message};
    } else {
      return;
    }

  }
};
//리트리 아이디별 거래소 총합 수익 캘린더 api
const getExchangeTotalApi = async (user_no) => {
  try {
    const response = await API.get(
      `total_profit?user_no=${user_no}`
    );
    return response.data;
  } catch (error) {
    return;
  }
};

export default {
  getCalenderApi,
  getPaybackLogApi,
  getExchangeTotalApi,
};
